import React from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../../components/Layout'
import BlogGames2021 from '../../components/BlogGames2021'
export default class RoomsIndexPage extends React.Component {
  render() {
    return (
      <Layout>

        <Helmet titleTemplate="%s | Room">
            <title>2020-2021 Season | Northcoast Game of the Week</title>
            <meta
                name="description"
                content="2021-2022 Season | Northcoast Game of the Week"
            />
        </Helmet>
        <h1>2020-2021 Season</h1>
        <BlogGames2021 />


      </Layout>
    )
  }
}
